/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { injectIntl } from "gatsby-plugin-intl"

import Header from "./header"
//import 'bootstrap/scss/bootstrap.scss';
import "../styles/styles.scss"

const Layout = ({ children, intl }) => {
    return (
        <>
            <Header />
            <main>{children}</main>
            <footer>
                <div>
                    <div className="logo"></div>
                    <p>© {new Date().getFullYear()} dariuszwegiera.com</p>
                </div>
            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
