import { injectIntl } from "gatsby-plugin-intl"
import React from "react"

class SendButton extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.sending) {
      return (<button className="btn blue btn-block" type="button" disabled>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> {this.props.intl.formatMessage({id: "inquiry.form.buttonSending"})}
      </button>);
    }

    return (
      <button className="btn blue btn-block" type="submit">{this.props.intl.formatMessage({id: "inquiry.form.buttonSend"})}</button>
    );
  };

}

export default injectIntl(SendButton);
