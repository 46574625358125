import { FormattedMessage, injectIntl } from "gatsby-plugin-intl"
import React from "react"

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import SendButton from './send-button'

class SendInquiry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      showSuccess: false,
      successClass: 'd-none',
      displayErrors: false,
      sending: false,
      email: ''
    }

    this.handleShow = this.handleShow.bind(this);
    this.handleShowSuccess = this.handleShowSuccess.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    const setErrors = (errors) => {
        this.setState({ displayErrors: errors });
    };

    event.preventDefault();

    if (!event.target.checkValidity()) {
      setErrors(true);
      return;
    }

    setErrors(false);

    const data = new FormData(event.target);

    this.setState({
      sending: true
    });

    fetch('/.netlify/functions/send', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: data.get('email'),
        message: data.get('message'),
        language: data.get('language')
      })
    }).then(response => {
      if(response.status === 422){
        this.setState({
          sending: false
        });

        return false;
      }

      this.setState({
        sending: false,
        showForm: false,
        showSuccess: true,
        email: data.get('email')
      });
    })
  }

  handleShow(event) {
    this.setState({
      showForm: true,
      showSuccess: false
    });
  }

  handleShowSuccess(event) {
    this.setState({
      showForm: false,
      showSuccess: true
    });
  }

  handleClose(event) {
    this.setState({
      showForm: false,
      showSuccess: false
    });
  }

  render() {
    const { displayErrors } = this.state;

    return (
      <>
        <button className="btn blue" onClick={this.handleShow}><FormattedMessage id="actions.sendInquiry" /></button>
        <Modal size="lg" show={this.state.showForm} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
            <FormattedMessage id="inquiry.form.title" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col md={{ span: 10, offset: 1 }}>
                <Form onSubmit={this.handleSubmit} className={displayErrors ? 'displayErrors' : ''} noValidate>
                  <Form.Group controlId="form.email">
                    <Form.Label>{this.props.intl.formatMessage({id: "inquiry.form.emailLabel"})}</Form.Label>
                    <Form.Control name="email" type="email" placeholder={this.props.intl.formatMessage({id: "inquiry.form.emailPlaceholder"})} required />
                  </Form.Group>
                  <Form.Group controlId="form.language">
                    <Form.Label>{this.props.intl.formatMessage({id: "inquiry.form.languageLabel"})}</Form.Label>
                    <Form.Control as="select" required name="language">
                      <option value="">{this.props.intl.formatMessage({id: "inquiry.form.languagePlaceholder"})}</option>
                      <option value="Niemiecki">{this.props.intl.formatMessage({id: "inquiry.form.languages.german"})}</option>
                      <option value="Angielski">{this.props.intl.formatMessage({id: "inquiry.form.languages.english"})}</option>
                      <option value="Duński">{this.props.intl.formatMessage({id: "inquiry.form.languages.danish"})}</option>
                      <option value="Szwedzki">{this.props.intl.formatMessage({id: "inquiry.form.languages.swedish"})}</option>
                      <option value="Niderlandzki">{this.props.intl.formatMessage({id: "inquiry.form.languages.dutch"})}</option>
                      <option value="Włoski">{this.props.intl.formatMessage({id: "inquiry.form.languages.italian"})}</option>
                      <option value="Norweski">{this.props.intl.formatMessage({id: "inquiry.form.languages.norwegian"})}</option>
                      <option value="Francuski">{this.props.intl.formatMessage({id: "inquiry.form.languages.french"})}</option>
                      <option value="Inny">{this.props.intl.formatMessage({id: "inquiry.form.languages.other"})}</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="form.message">
                    <Form.Label>{this.props.intl.formatMessage({id: "inquiry.form.messageLabel"})}</Form.Label>
                    <Form.Control name="message" required as="textarea" rows="3" placeholder={this.props.intl.formatMessage({id: "inquiry.form.messageLabel"})} />
                  </Form.Group>
                  <Form.Group controlId="form.rodo" className="label-rodo">
                    <Form.Check value="1" name="rodo" required type="checkbox" label={this.props.intl.formatMessage({id: "inquiry.form.rodo"})}></Form.Check>
                  </Form.Group>
                  <Form.Group className="submit" controlId="form.submit">
                    <SendButton sending={this.state.sending} />
                  </Form.Group>
                </Form>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        <Modal size="lg" show={this.state.showSuccess} onHide={this.handleClose} className="success">
          <Modal.Header closeButton>
            <Modal.Title>
              <span className="icon icon-tick"></span>
              {this.props.intl.formatMessage({id: "inquiry.success.title"})}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><FormattedMessage id="inquiry.success.message" values={{address: this.state.email}} /></p>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default injectIntl(SendInquiry);
