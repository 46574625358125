import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import Dropdown from 'react-bootstrap/Dropdown';

const languageName = {
    pl: "PL",
    en: "EN",
    de: "DE",
}

class Language extends React.Component {
  render() {
    return (
        <div className="dropdown">
          <Dropdown>
            <Dropdown.Toggle variant="default" id="dropdown-basic">
              {this.props.currentLocale}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <IntlContextConsumer>
                  {({ languages, language: currentLocale }) =>
                      languages.map(language => (
                          <div
                              key={language}

                              className={currentLocale === language ? 'selected' : ''}
                          >
                              <Dropdown.Item href={language === 'pl' ? '/' : "/" + language} onClick={() => changeLocale(language)}>{languageName[language]}</Dropdown.Item>
                          </div>
                      ))
                  }
              </IntlContextConsumer>
            </Dropdown.Menu>
          </Dropdown>
        </div>
    )
  }
}

export default Language
