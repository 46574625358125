import { injectIntl, changeLocale } from "gatsby-plugin-intl"
import React from "react"
import Language from "./language"
import SendInquiry from './send-inquiry.js'
import Scrollspy from 'react-scrollspy'
import animateScrollTo from 'animated-scroll-to';

let lastScrollY = 0;
let ticking = false;

const images = require.context('../images/', true);

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      class: '',
      mobileClass: ''
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  isAnyPartOfElementInViewport = el => {
      const rect = el.getBoundingClientRect();
      // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

      // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
      const vertInView = (rect.top <= windowHeight) && (((rect.top-88) + rect.height) >= 0);

      return vertInView;
  }

  handleScroll = () => {
    lastScrollY = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {

        if(lastScrollY > 50){
          this.setState({ class: 'sticky' });
        }
        else{
          this.setState({ class: '' });
        }

        ticking = false;
      });

      ticking = true;
    }
  };


  menuClick = event => {
    event.preventDefault();
    event.stopPropagation();

    let offset = -88;

    if(this.state.mobileClass === 'menu-visible'){
      offset = -64;
    }

    if(event.currentTarget.dataset.section === 'hero'){
      offset = 0;
    }

    animateScrollTo(document.querySelector('#'+event.currentTarget.dataset.section), {verticalOffset: offset, maxDuration: 600});

    this.setState({ mobileClass: '' });
  }

  expandMenu = event => {
    event.preventDefault();
    event.stopPropagation();

    if(this.state.mobileClass !== 'menu-visible'){
      this.setState({ mobileClass: 'menu-visible' });
    }
    else{
      this.setState({ mobileClass: '' });
    }
  }

  getOffset = () => {
    if(this.state.mobileClass === 'menu-visible'){
      return -100;
    }

    return -88;
  }

  render() {
    return (
      <header className={this.state.class+" "+this.state.mobileClass}>
        <div className="container">
          <div className="row">
              <h1 className="logo" data-section="hero" onClick={this.menuClick}>
                <div className="symbol"></div>
                <div className="typo">
                  {this.props.intl.formatMessage({ id: "logo.name" })}<br /><span>{this.props.intl.formatMessage({ id: "logo.subtitle" })}</span>
                </div>
              </h1>
              <nav className="site-menu">
                <Scrollspy items={ ['hero', 'offer', 'about', 'pricing', 'contact'] } currentClassName="selected" offset={this.getOffset()}>
                  <li className="menu-item"><a href="#hero" data-section="hero" onClick={this.menuClick}>{this.props.intl.formatMessage({ id: "menu.start" })}</a></li>
                  <li className="menu-item"><a href="#offer" data-section="offer" onClick={this.menuClick}>{this.props.intl.formatMessage({ id: "menu.offer" })}</a></li>
                  <li className="menu-item"><a href="#about" data-section="about" onClick={this.menuClick}>{this.props.intl.formatMessage({ id: "menu.about" })}</a></li>
                  <li className="menu-item"><a href="#pricing" data-section="pricing" onClick={this.menuClick}>{this.props.intl.formatMessage({ id: "menu.pricing" })}</a></li>
                  <li className="menu-item"><a href="#contact" data-section="contact" onClick={this.menuClick}>{this.props.intl.formatMessage({ id: "menu.contact" })}</a></li>
                  {/* <li className="menu-item menu-dropdown">
                    <Language currentLocale={this.props.intl.locale} />
                  </li> */}
                  <li className="menu-item menu-send-inquiry"><SendInquiry /></li>
                </Scrollspy>
              </nav>
              {/*<nav className="menu-mobile-language">
                <h3>{this.props.intl.formatMessage({ id: "menu.language" })}</h3>
                <ul>
                  <li className={"menu-item " + (this.props.intl.locale === 'pl' ? 'selected' : '')}>
                  <a href="#" onClick={() => changeLocale('pl')}>
                    Polski
                    <div className="flag">
                      <img src={images('./pl.svg')} width="30" height="30" alt="" />
                    </div>
                  </a>
                  </li>
                  <li className={"menu-item " + (this.props.intl.locale === 'de' ? 'selected' : '')}>
                    <a href="#" onClick={() => changeLocale('de')}>
                      Deutsch
                      <div className="flag">
                        <img src={images('./de.svg')} width="30" height="30" alt="" />
                      </div>
                    </a>
                  </li>
                  <li className={"menu-item " + (this.props.intl.locale === 'en' ? 'selected' : '')}>
                    <a href="#" onClick={() => changeLocale('en')}>
                      English
                      <div className="flag">
                        <img src={images('./en.svg')} width="30" height="30" alt="" />
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>*/}
              <button type="button" className="navbar-toggle" onClick={this.expandMenu}>
                <span className="sr-only">Menu</span>
                <span className={"icon " + (this.state.mobileClass === 'menu-visible' ? 'icon-close' : 'icon-menu')}></span>
              </button>
          </div>
        </div>
      </header>
    );
  }
}

export default injectIntl(Header);
